export const helpers = {
    methods: {
        formatCurrency(value) {
            const v = parseFloat(value)
            const currency = window.isUkSite ? 'GBP' : 'EUR';
            const options = { style: 'currency', currency: currency }
            let lang = window.language ? (window.isUkSite ? "en-GB" : `${window.language}-BE`) : "nl-BE"
            return new Intl.NumberFormat(lang, options).format(v);
        },
    
        formatNumber(value) {
            const v = parseFloat(value)
            const options = { maximumFractionDigits: 0 }
            let lang = window.language ? (window.isUkSite ? "en-GB" : `${window.language}-BE`) : "nl-BE"
            return new Intl.NumberFormat(lang, options).format(v);
        },
    
        formatDate(date) {
            const d = new Date(date * 1e3) // Unix timestamp is in seconds, we need ms
            const options = { dateStyle: 'medium' }
            let lang = window.language ? (window.isUkSite ? "en-GB" : `${window.language}-BE`) : "nl-BE"
            return new Intl.DateTimeFormat(lang, options).format(d);
        },
    
        capitalize(string) {
            return string[0].toUpperCase() + string.toLowerCase().slice(1);
        },

        setSalesAppUri(inSalesApp, inKioskApp, url) {
            if(inSalesApp) {
                return url.replace("webshop", "sales-app")
            }
            if(inKioskApp) {
                return url.replace("webshop", "kiosk-app")
            }
            return url
        },
    
        toggleAccordion(id) {
            this.currentOpenAccordions.includes(id) ? this.currentOpenAccordions.splice(this.currentOpenAccordions.indexOf(id), 1) : this.currentOpenAccordions.push(id);
        },

        async toggleOrdersAccordion(event) {

            const currentRow = event.target.closest('tr')
            const orderId = currentRow.dataset.orderid
            const injectElement = document.getElementById('order-' + orderId)

            if (this.currentOpenAccordions.includes(currentRow.id)) {
                this.currentOpenAccordions.splice(this.currentOpenAccordions.indexOf(currentRow.id), 1)
            } else {

                this.currentOpenAccordions.push(currentRow.id)

                const response = await fetch(`${window.rootUrl}sales-app/ajax/line-item?orderId=${ orderId }`, {
                    method: 'GET'
                });

                if (response.status === 200 && response.ok) {
                    const templateHtml = await response.text();
                    if (templateHtml) {
                        injectElement.innerHTML = templateHtml;
                    }
                } else {
                    injectElement.innerHTML = `
                <tr>
                    <td colspan="5">Kon bestellingen niet laden.</td>
                </tr>
            `;
                }
            }
        },
    
        focusQty(event) {
            let currentValue = event.target.value
            if (currentValue === "1") event.target.value = ""
        },
    
        blurQty(event) {
            let currentValue = event.target.value
            if (currentValue === "" || currentValue === "0") event.target.value = 1
        },
    
        // Also called from product.twig
        technicalSheetUrl(loggedIn, productId, url, sku, name) {
            if (!loggedIn) {
                // Open pay by email modal from within a Vue component (emit event to pass data to parent)
                this.$emit('show-pay-by-email-modal', { type: "techsheet", modalData: productId, sku: sku, name: name })
                // Open pay by email from within a Twig template (we can call the function inside index.js directly)
                if (this.togglePayByEmailModal) this.togglePayByEmailModal({ type: "techsheet", modalData: productId, sku: sku, name: name })
            } else {
                // Open file
                window.open(url,'_blank');
            }
        },
    
        debounce(fn, ms = 0) {
            let timer;
            return (...args) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    fn.apply(this, args);
                }, ms);
            }
        },

        getMinQty(multiples) {
            if (multiples) {
                // Check if customer is linked to one of the inventSiteId's in the object
                const inventSiteId = this.user?.inventSiteId ?? ''
                const specificMultiple = multiples.filter((el) => el.inventSiteId == inventSiteId)
                const globalMultiple = multiples.filter((el) => el.inventSiteId == '' || el.inventSiteId == null)

                if (specificMultiple.length) {
                    return (specificMultiple[0].lowestQty > 0) ? specificMultiple[0].lowestQty : 1
                }

                if (globalMultiple.length) {
                    return (globalMultiple[0].lowestQty > 0) ? globalMultiple[0].lowestQty : 1
                }

            } else {
                return 1
            }
        },

        getStepQty(multiples) {
            if (multiples) {
                // Check if customer is linked to one of the inventSiteId's in the object
                const inventSiteId = this.user?.inventSiteId ?? ''
                const specificMultiple = multiples.filter((el) => el.inventSiteId == inventSiteId)
                const globalMultiple = multiples.filter((el) => el.inventSiteId == '' || el.inventSiteId == null)

                if (specificMultiple.length) {
                    return (specificMultiple[0].multipleQty > 0) ? specificMultiple[0].multipleQty : 1
                }

                if (globalMultiple.length) {
                    return (globalMultiple[0].multipleQty > 0) ? globalMultiple[0].multipleQty : 1
                }

            } else {
                return 1
            }
        },
    },
    
    computed: {
        currencySymbol() {
            return window.isUkSite ? '£' : '€';
        }
    }
};
