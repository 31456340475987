export const cart = {
    methods: {
        deleteLineItem(id) {
            document.getElementById("remove-" + id).value = 1;
            let data = new FormData(document.getElementById("form-cart"));
        
            window.axios({
                url: `${ window.rootUrl }actions/commerce/cart/update-cart`,
                method: 'POST',
                data
            }).then(result => {
                if (result.status === 200) {
                    // Remove item from DOM
                    const item = document.getElementById("item-" + id);
                    item.parentNode.removeChild(item);
                
                    // Show notification
                    this.$notify({
                        text: window.i18n.t("success.productDeleted"),
                        type: "success",
                    });
                
                    // Save cart
                    if (result?.data?.cart) {
                        this.$store.dispatch('saveCart', result.data.cart);
                    }
                } else {
                    this.$notify({
                        title: window.i18n.t("errors.generalTitle"),
                        text: window.i18n.t("errors.notDeletedFromCart"),
                        type: "error",
                    });
                    console.error("Cart error.", result);
                }
            }).catch((error) => {
                this.$notify({
                    title: window.i18n.t("errors.generalTitle"),
                    text: window.i18n.t("errors.cartNotUpdated"),
                    type: "error",
                });
                console.error("Cart error.", error);
            });
        },

        getProductPrice(product, qty, user) {
            fetch(`${ window.rootUrl }actions/ranson-module/customer/price`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': window.csrfToken
                },
                body: JSON.stringify({
                    useDb: true,
                    itemId: product.sku,
                    customer: user.id ?? null,
                    qty: qty,
                    requestedDeliveryDate: null
                })
            }).then(response => {
                if (!response.ok) throw new Error("HTTP error " + response.status);
                return response.json();
            }).then(result => {
                if (result.price !== null || result.price !== "undefined") {
                    console.log(result)
                }
            })
        },
    
        updateLineItemQty(e, product, user) {

            const inputEl = e.target;
            // Now get the buttons in the form
            const button = document.getElementById('submit-cart-button');

            const minQty = parseInt(inputEl.min);
            const step = parseInt(inputEl.step);
            const qty = inputEl.value;

            // Check for mandatory multiples
            if(minQty) {
                if (qty < minQty) {
                    this.$notify({
                        text: window.i18n.t("errors.minQtyError", { min: minQty }),
                        type: "error",
                    });

                    // Disable buttons
                    button.disabled = true;

                    return false;
                }
            }

            if(step) {
                if ((qty % step) !== 0) {
                    this.$notify({
                        text: window.i18n.t("errors.stepQtyError", { step }),
                        type: "error",
                    });

                    // Disable buttons
                    button.disabled = true;

                    return false;
                }
            }

            // Get price for quantity
            this.getProductPrice(product, qty, user);

            // Enabled buttons
            button.disabled = false;

            // Only change if quantity is not 0 -> else item gets deleted
            if (parseInt(inputEl.value) !== 0) {

                let data = new FormData(document.getElementById("form-cart"));

                window.axios({
                    url: `${ window.rootUrl }actions/commerce/cart/update-cart`,
                    method: 'POST',
                    data
                }).then(result => {

                    if (result.status === 200) {

                        if(result.data.success) {
                            // Show notification
                            this.$notify({
                                text: window.i18n.t("success.quantityChanged"),
                                type: "success",
                            });

                            // Save cart
                            if (result?.data?.cart) this.$store.dispatch('saveCart', result.data.cart).then(() => {
                                this.calculateCartShippingDifference()
                            });

                        } else {

                            let errors = []

                            // Loop through errors
                            Object.entries(result.data.errors).forEach(error => {
                                errors.push(error[1][0])
                            })

                            e.target.value = minQty

                            this.$notify({
                                title: window.i18n.t("errors.generalTitle"),
                                text: errors.join('<br>'),
                                type: "error",
                            });
                        }

                    } else {
                        this.$notify({
                            title: window.i18n.t("errors.generalTitle"),
                            text: window.i18n.t("errors.productNotChanged"),
                            type: "error",
                        });
                        console.error("Cart error.", result);
                    }
                }).catch((error) => {
                    this.$notify({
                        title: window.i18n.t("errors.generalTitle"),
                        text: window.i18n.t("errors.cartNotUpdated"),
                        type: "error",
                    });
                    console.error("Cart error.", error);
                });
            }
        },

        calculateCartShippingDifference() {
            const minDeliveryWrapperElement = document.querySelector('div.delivery-cost')
            if(minDeliveryWrapperElement !== null) {
                const minDeliveryElement = minDeliveryWrapperElement.querySelector('span.shipping-difference')
                if (minDeliveryElement !== null) {
                    const minDeliveryAmount = minDeliveryWrapperElement.dataset.mindelivery
                    let shippingDifference = minDeliveryAmount - this.getCartTotal
                    minDeliveryElement.textContent = this.formatCurrency(shippingDifference);
                }
            }
        },
    }
};
